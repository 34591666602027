import React, { useEffect, useState } from 'react';
import { Form, Input, message, Tabs } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateContext } from "../../contexts/ContextProvider";
import background from "../../data/bmw-bg.jpg";

const Login = () => {
    const [formLogin] = Form.useForm();
    const [formRegister] = Form.useForm();
    const [activeTab, setActiveTab] = useState("login");
    const [errorMessage, setErrorMessage] = useState("");
    const {token, setToken, setUserRole} = useStateContext();
    const [phone, setPhone] = useState("");
    const [registrationEmail, setRegistrationEmail] = useState("");
    

    const navigate = useNavigate();
    const location = useLocation();
    const from = ((location.state)?.from.pathname) || '/';

    useEffect(() => {
        if (token) {
            // Dacă token există, navighează către dashboard
            navigate(from, { replace: true });
          }
    }, [token, navigate, from])

    const handleLogin = async (values) => {
        const { email, password } = values; // Destructurează valorile email și parolă din formular
    
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions);
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.detail || "An error occurred during login.");
            }
    
            setToken(data.token); // Salvează token-ul primit în context
            setUserRole(data.role); // Salvează rolul utilizatorului în context
            navigate(from, { replace: true }); // Navighează la pagina de origine sau la pagina de start
        } catch (error) {
            setErrorMessage(error.message);
        }
    };


    const handleRegister = async (values) => {
        const { email, password, phone, firstname, lastname } = values; // Destructurează valorile email și parolă din formular
    
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                phone: phone
            }),
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/create_user`, requestOptions);
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.detail || "An error occurred during login.");
            }
                message.success("Va multumim, va contacam pentru activarea acc.")
            navigate("/"); // Navighează la pagina de origine sau la pagina de start
        } catch (error) {
            setErrorMessage(error.message);
        }
    };


    const tabItems = [
        {
            label: "Login",
            key: "login",
            children: (
                // Conținutul pentru tabul de Login
                <Form form={formLogin} layout="vertical" onFinish={handleLogin}>
                            
                            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Te rog introdu adresa de email!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="password" label="Parolă" rules={[{ required: true, message: 'Te rog introdu parola!' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <button htmlType="submit" className="w-full py-4 rounded-lg bg-purple-500 hover:bg-purple-700 text-white">
                                    Login
                                </button>
                    </Form.Item>
                </Form>
            ),
        },
        {
            label: "Înregistrare",
            key: "register",
            children: (
                // Conținutul pentru tabul de Înregistrare
                <Form form={formRegister} layout="vertical" onFinish={handleRegister}>

                            <Form.Item name="firstname" label="Prenume" rules={[{ required: true, message: 'Te rog introdu adresa de Prenumele!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="lastname" label="Nume" rules={[{ required: true, message: 'Te rog introdu adresa de Numele!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Te rog introdu adresa de email pentru înregistrare!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="password" label="Parolă" rules={[{ required: true, message: 'Te rog introdu parola!' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item name="phone" label="Telefon" rules={[{ required: true, message: 'Te rog introdu numărul de telefon!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <button type="primary" htmlType="submit" className="w-full py-4 rounded-lg bg-green-500 hover:bg-green-700 text-white">
                                    Înregistrează-te
                                </button>
                    </Form.Item>
                </Form>
            ),
        },
    ];


    return (

        <div className="min-h-screen flex justify-center items-center bg-gray-50" style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundBlendMode: 'overlay', backgroundColor: 'rgba(255,255,255,0.93)'}}>
        <div className="max-w-md w-3/4 space-y-8">
            <Tabs defaultActiveKey="login" centered onChange={setActiveTab} items={tabItems} />
        </div>
    </div>


    );
};

export default Login;

