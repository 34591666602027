import React from 'react';
import { Spin } from 'antd';

const FullScreenLoader = () => {
  return (
    <div className="w-screen h-screen grid content-center">
       <Spin  fullscreen />
  </div>
  );
};

export default FullScreenLoader;